body {
}

* {
	box-sizing: border-box;
}

.column {
	float: left;
	width: 33.33%;
	padding: 10px;
	height: auto; 
}

.row:after {
	content: "";
	display: table;
	clear: both;
}

.footer {
	/*padding: 10px 0;*/
	background-color: #007298;
	border-top: 6px solid #007298;
	/*padding: 5px 20px 25px 20px;*/
	color: #aeb9cb;
	font-size: 11px;
	min-height: 195px;
	/*min-width: 950px;*/
}

.footer__inner {
	padding: 10px 25px;
	text-align: center;
}

.footer_left {
	float: left;
	padding-top: 0;
	width: 33.33333%;
}

.footer_center {
	float: left;
	width: 33.33333%;
	text-align: center;
}

.footer_right {
	float: left;
	width: 33.33333%;
	text-align: right;
}

.Whitebox {
	margin: 0 auto;
	border: 2px solid #d3d3d3;
	color: #ffffff;
	width: 280px;
	padding: 5px 10px;
	left: 10px;
	font-size: 13px;
}

.hag-logo {
	width: 126px;
	height: 40px;
}

.footerPoweredBy {
	padding-top: 5px;
	margin: 0;
	font-weight: 400;
	font-size: 11px;
	color: #D3D3D3;
	font-family: 'Lato', sans-serif;
	line-height: 1.428571429;
}

.footerCopyright {
	color: #D3D3D3;
	font-size: 11px;
	margin-top: 0px;
}

.footerLeft {
	margin-left: 10px;
	width: 275px;
	font-size: 12px;
	padding-top: 5px;
	color: #ffffff;
	line-height: 1.428571429;
}

	.footerLeft a {
		color: #ffffff;
		text-decoration: none;
	}

.footerLeft_Text {
	border: 2px solid #d3d3d3;
	color: #ffffff;
	width: 200px;
	padding: 5px;
	margin-left: 11px;
	font-size: 11px;
	text-align: center;
	white-space: nowrap;
	line-height: 1.428571429;
}

.footercode {
	color: #ffffff;
	width: 275px;
	margin-left: 10px;
	font-size: 12px;
	line-height: 1.428571429;
}

.footer_capri {
	font-size: 11px;
	border-collapse: initial;
	color: #ffffff;
}

.footer_capr_sign4compliance_span {
	margin-left: 14px;
}

.footer_capr_sign4compliance_div {
	margin: 3px 0px 3px 75px;
}

.footer_managedby {
	float: right;
	width: 250px;
}

.transcendLogo {
	position: relative;
	left: 0px;
}

@media only screen and (device-width : 375px) and (device-height : 667px) and (-webkit-device-pixel-ratio : 2), screen and (min-width: 360px) and (max-width: 767px), screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3), screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3), screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3), screen and (max-device-width: 640px), screen and (max-device-width: 667px) and (max-width: 480px), screen and (min-device-width : 375px) and (max-device-width : 667px) {
	.column {
		width: 100%;
		padding: 10px 25px;
		text-align: center;
	}

	.footer_capri {
		text-align: center;
		margin-left: 20px;
	}

	.Whitebox {
		margin: 0px;
	}

	.footer_managedby {
		float: none;
		margin-left: 25px;
	}

	.footer {
		height:415px;
	}
}